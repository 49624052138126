<template>
  <div class="content-layout">
    <div class="scence-operations">
      <div>
        <a-button
          v-if="$hasPermissions(['rcs:scene_type:create'])"
          type="primary"
          class="add-btn"
          @click="handleCreator"
          >新增</a-button
        >
      </div>
      <div class="data-filter">
        <a-select
          v-model="languageSelect"
          placeholder="请选择翻译语言"
          showSearch
          optionFilterProp="children"
          @change="listPageData"
          :style="{ width: '8vw', marginRight: '10px' }"
        >
          <a-select-option v-for="(language, key) in languageOptions" :key="key" :value="language.value">
            {{ language.label }}
          </a-select-option>
        </a-select>
        <a-input
          v-model="listRequest.name"
          class="search-keyword"
          allow-clear
          placeholder="请输入场景类型名称"
          @keyup.enter.native="doSearch"
        ></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch">搜索</a-button>
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :columns="this.$constRepository.scenceType.columns"
      :data-source="tableData"
      class="scence-table"
      rowKey="id"
    >
      <template slot="createdTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="lastModificationTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="creatorView" slot-scope="scope">
        <span v-if="scope">{{ scope.creatorName }}</span>
        <span v-else>-</span>
      </template>
      <template slot="regions" slot-scope="scope">
        <span v-if="scope">
          {{ scope.map((region) => region.name).join('、') }}
        </span>
        <span v-else>-</span>
      </template>
      <template slot="supportPlatforms" slot-scope="scope">
        <span v-if="scope.length">
          {{ scope.map((platform) => $constRepository.scenceType.supportPlatformNames[platform]).join('、') }}
        </span>
        <span v-else>-</span>
      </template>
      <template slot="status" slot-scope="scope">
        <a-switch
          :checkedChildren="'启用'"
          :unCheckedChildren="'禁用'"
          :disabled="!$hasPermissions(['rcs:scene_type:update'])"
          :checked="switchStatus(scope.status)"
          @change="handleSwitchChange(scope)"
        />
      </template>
      <template slot="action" slot-scope="scope">
        <a-button
          v-if="$hasPermissions(['rcs:scene_type:update'])"
          :disabled="languageSelect !== $constRepository.language.FILTER_OPTION_ZH_CN.value"
          class="green-btn"
          ghost
          @click="handleEdit(scope)"
          >编辑</a-button
        >
        <a-button v-if="$hasPermissions(['rcs:scene_type:get'])" class="green-btn" ghost @click="handleInfo(scope)"
          >详情</a-button
        >
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
    <ScenceTypeFormModal ref="scenceTypeFormModal" />
    <ScenceTypeInfoModal ref="scenceTypeInfoModal" />
  </div>
</template>

<script>
import ScenceTypeFormModal from './components/ScenceTypeFormModal';
import ScenceTypeInfoModal from './components/ScenceTypeInfoModal';

export default {
  name: 'ScenceTypeManagement',
  components: { ScenceTypeFormModal, ScenceTypeInfoModal },
  data() {
    return {
      languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
      languageOptions: this.$constRepository.language.FILTER_OPTIONS,
      listRequest: {
        name: undefined,
      },
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
        currentPage: 0,
      },
      tableData: [],
    };
  },
  computed: {
    switchStatus: {
      get() {
        return (status) => status === this.$constRepository.scenceType.status.enable;
      },
    },
  },
  methods: {
    handleCreator() {
      this.$refs.scenceTypeFormModal.showCreateDialog(async (scenceType) => {
        try {
          await this.$apiManager.scenceType.createScenceType(scenceType);
          this.$message.success('场景类型创建成功');
          this.listPageData();
        } catch (err) {
          this.$message.error('场景类型创建失败');
        } finally {
          this.$refs.scenceTypeFormModal.hideDialog();
        }
      });
    },
    handleEdit(scenceType) {
      this.$refs.scenceTypeFormModal.showUpdateDialog(scenceType, async (result) => {
        try {
          await this.$apiManager.scenceType.updateScenceType({
            id: result.id,
            updateDto: result,
          });
          this.$message.success('场景类型更新成功');
          this.listPageData();
        } catch (err) {
          this.$message.error('场景类型更新失败');
        } finally {
          this.$refs.scenceTypeFormModal.hideDialog();
        }
      });
    },
    async fetchScenceList() {
      const params = {
        ...this.listRequest,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const headers = {
        'Accept-Language': this.languageSelect,
      };
      const response = await this.$apiManager.scenceType.getScenceTypeList(params, headers);
      this.tableData = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async listPageData() {
      await this.fetchScenceList();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        this.fetchScenceList();
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.listPageData();
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.listPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.listPageData();
    },
    async handleSwitchChange(scenceType) {
      try {
        await this.$apiManager.scenceType.updateStatus(
          scenceType.id,
          scenceType.status === this.$constRepository.scenceType.status.disable
            ? this.$constRepository.scenceType.status.enable
            : this.$constRepository.scenceType.status.disable,
        );
        this.$message.success('状态更改成功');
        this.listPageData();
      } catch (error) {
        this.$message.warn('状态更改失败');
      }
    },
    handleInfo(scenceType) {
      this.$refs.scenceTypeInfoModal.showInfoDialog(scenceType);
    },
  },
  activated() {
    this.listPageData();
  },
};
</script>

<style scoped lang="less">
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.scence-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1%;
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .scence-table {
    margin-top: 2%;
  }
}
</style>
