<template>
  <a-modal
    :visible="visible"
    :title="isUpdate ? '修改场景类型' : '创建场景类型'"
    width="40%"
    centered
    okText="确认"
    cancelText="取消"
    :destroyOnClose="true"
    @ok="onSubmit"
    @cancel="hideDialog"
  >
    <a-skeleton v-if="loading" active />
    <a-form-model v-else layout="vertical" :rules="rules" :model="scenceType" ref="form">
      <a-form-model-item label="ID" prop="id">
        <a-input :disabled="isUpdate" v-model="scenceType.id" placeholder="请输入场景类型ID" />
      </a-form-model-item>
      <a-form-model-item label="场景类型名称" prop="name">
        <a-input v-model="scenceType.name" placeholder="请输入场景类型名称" />
      </a-form-model-item>
      <a-form-model-item label="发布区域" prop="regionIdentifiers">
        <a-input
          read-only
          :value="`已选择${scenceType.regionIdentifiers.length}个(${scenceType.regionIdentifiers.map(
            (region) => region,
          )})`"
          @click="handleRegionSelect"
        >
          <a-tooltip slot="suffix" title="Extra information">
            <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="平台" prop="supportPlatforms">
        <a-select placeholder="请选择平台" mode="multiple" v-model="scenceType.supportPlatforms">
          <a-select-option
            v-for="platform in $constRepository.scenceType.supportPlatforms"
            :key="platform"
            :value="platform"
          >
            {{ $constRepository.scenceType.supportPlatformNames[platform] }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="图片" prop="images">
        <a-card :tab-list="tabList" :active-tab-key="tabKey" @tabChange="(key) => (tabKey = key)" v-if="scenceType.images.length">
          <a-form :model="scenceType.images[tabKey - 1]" layout="vertical">
            <a-row :gutter="[24, 16]" align="bottom">
              <a-col class="w-full">
                <a-form-model-item label="场景类型实体图" prop="solidImages">
                  <div class="clearfix">
                    <a-upload
                      list-type="picture-card"
                      accept="image/*"
                      :customRequest="solidCustomRequest"
                      :data="scenceType.images[tabKey - 1]"
                      :file-list="scenceType.images[tabKey - 1].solidImages"
                      @preview="handlePreview"
                      :remove="removeSolidImages"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">Upload</div>
                      </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                      <img alt="example" style="width: 100%;background-color: #e6e6e6;" :src="previewImage">
                    </a-modal>
                  </div>
                </a-form-model-item>
              </a-col>
              <a-col class="w-full">
                <a-form-model-item label="场景类型线框图" prop="lineImages">
                  <div class="clearfix">
                    <a-upload
                      list-type="picture-card"
                      accept="image/*"
                      :customRequest="lineCustomRequest"
                      :data="scenceType.images[tabKey - 1]"
                      :file-list="scenceType.images[tabKey - 1].lineImages"
                      @preview="handlePreview"
                      :remove="removeLineImages"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">Upload</div>
                      </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                      <img alt="example" style="width: 100%;background-color: #e6e6e6;" :src="previewImage" />
                    </a-modal>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status">
        <a-switch
          :checkedChildren="'启用'"
          :unCheckedChildren="'禁用'"
          :checked="switchStatus(scenceType.status)"
          @change="handleSwitchChange"
        />
      </a-form-model-item>
    </a-form-model>
    <region-select-modal ref="regionSelectModal" />
    <upload-progress ref="uploadProgress" />
  </a-modal>
</template>

<script>
import lodash from 'lodash';
import RegionSelectModal from '../../region/components/RegionSelectModal';
import UploadProgress from '../../../../components/upload-progress/UploadProgress';

export default {
  name: 'ScenceTypeFormModal',
  components: { RegionSelectModal, UploadProgress },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      visible: false,
      isUpdate: false,
      loading: false,
      scenceType: {
        name: undefined,
        value: undefined,
        regionIdentifiers: [],
        regions: [],
        status: this.$constRepository.scenceType.status.enable,
        images: [],
      },
      tabList: [
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.AIHOME}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[this.$constRepository.loadedDevice.ImageTypes.AIHOME],
        },
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[
            this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE
          ],
        },
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.NEXSMART}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[this.$constRepository.loadedDevice.ImageTypes.NEXSMART],
        },
      ],
      tabKey: `${this.$constRepository.loadedDevice.ImageTypes.AIHOME}`,
      callback: undefined,
      rules: {
        id: [{ required: true, message: 'ID 不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '场景类型名称 不能为空', trigger: 'blur' }],
      },
    };
  },
  computed: {
    switchStatus: {
      get() {
        return (status) => status === this.$constRepository.scenceType.status.enable;
      },
    },
  },
  methods: {
    onSubmit() {
      if (this.scenceType.images.length > 0) {
        let flag = true;
        this.scenceType.images.forEach((image) => {
          if (!image.type) {
            flag = false;
          }
        });
        if (!flag) {
          this.$message.warn('请选择图片所属项目');
          return;
        }
      }
      const images = this.scenceType.images.map((image) => ({
        ...image,
        solidImages: image.solidImages.map((item) => item.filePath),
        lineImages: image.lineImages.map((item) => item.filePath),
      }));
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          if (this.callback) {
            this.callback({...this.scenceType, images });
          }
        }
      });
    },

    hideDialog() {
      this.visible = false;
      this.isUpdate = false;
      this.callback = undefined;
      this.scenceType = {
        name: undefined,
        value: undefined,
        status: this.$constRepository.scenceType.status.enable,
        regionIdentifiers: [],
        regions: [],
        images: [],
      };
    },
    showCreateDialog(cb) {
      this.callback = cb;
      this.isUpdate = false;
      this.visible = true;
      this.$set(this.scenceType, 'images', []);
      this.initImages();
    },
    async showUpdateDialog(scenceType, cb) {
      this.visible = true;
      this.loading = true;
      await this.fetchScenceTypeById(scenceType.id);
      if (!this.scenceType.regionIdentifiers) {
        this.$set(this.scenceType, 'regionIdentifiers', []);
        this.$set(this.scenceType, 'regions', []);
      }
      if (!this.scenceType.images) {
        this.$set(this.scenceType, 'images', []);
      }
      this.initImages();
      this.callback = cb;
      this.isUpdate = true;
      this.loading = false;
    },
    async fetchScenceTypeById(id) {
      const res = await this.$apiManager.scenceType.getScenceType(id);
      this.scenceType = res.data;
    },
    handleRegionSelect() {
      const toDO = (select) => {
        this.scenceType.regionIdentifiers = select;
      };
      this.$refs.regionSelectModal.selectedRowKeys = this.scenceType.regionIdentifiers;
      this.$refs.regionSelectModal.showCreateDialog(toDO);
    },
    handleSwitchChange(checked) {
      this.$set(
        this.scenceType,
        'status',
        checked ? this.$constRepository.scenceType.status.enable : this.$constRepository.scenceType.status.disable,
      );
    },
    initImages() {
      // 填充images
      if (!this.scenceType.images[0]) {
        this.scenceType.images[0] = {
          type: this.$constRepository.loadedDevice.ImageTypes.AIHOME,
          solidImages: [],
          lineImages: [],
          solidImageDownloadUrls: [],
          lineImageDownloadUrls: [],
        };
      }
      if (!this.scenceType.images[1]) {
        this.scenceType.images[1] = {
          type: this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE,
          solidImages: [],
          lineImages: [],
          solidImageDownloadUrls: [],
          lineImageDownloadUrls: [],
        };
      }
      if (!this.scenceType.images[2]) {
        this.scenceType.images[2] = {
          type: this.$constRepository.loadedDevice.ImageTypes.NEXSMART,
          solidImages: [],
          lineImages: [],
          solidImageDownloadUrls: [],
          lineImageDownloadUrls: [],
        };
      }

      this.scenceType.images.forEach((image, imageIndex) => {
        const originSolidImages = lodash.cloneDeep(image.solidImages);
        const originLineImages = lodash.cloneDeep(image.lineImages);
        this.scenceType.images[imageIndex].solidImages.splice(0, this.scenceType.images[imageIndex].solidImages.length);
        this.scenceType.images[imageIndex].lineImages.splice(0, this.scenceType.images[imageIndex].lineImages.length);
        image.solidImageDownloadUrls.forEach((url, index) => {
          const solidImage = {
            url,
            filePath: originSolidImages[index],
            name: originSolidImages[index],
            uid: -index,
          };
          this.scenceType.images[imageIndex].solidImages.push(solidImage);
        });
        image.lineImageDownloadUrls.forEach((url, index) => {
          const lineImage = {
            url,
            filePath: originLineImages[index],
            name: originLineImages[index],
            uid: -index,
          };
          this.scenceType.images[imageIndex].lineImages.push(lineImage);
        });
      });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handlePreview(file) {
      const imageFile = file;
      if (!imageFile.url && !imageFile.preview) {
        imageFile.preview = await this.getBase64(imageFile.originFileObj);
      }
      this.previewImage = imageFile.url || imageFile.preview;
      this.previewVisible = true;
    },
    removeSolidImages(record) {
      // 找到是哪个卡片的图片需要删除
      const targetIndex = this.scenceType.images.findIndex((image) => image.solidImages.find((simg) => simg.uid === record.uid));
      this.scenceType.images[targetIndex].solidImages = this.scenceType.images[targetIndex].solidImages.filter(
        (image) => image.uid !== record.uid,
      );
    },
    removeLineImages(record) {
      const targetIndex = this.scenceType.images.findIndex((image) => image.lineImages.find((simg) => simg.uid === record.uid));
      this.scenceType.images[targetIndex].lineImages = this.scenceType.images[targetIndex].lineImages.filter(
        (image) => image.uid !== record.uid,
      );
    },
    async customRequest(request, module, submodule, imageFile) {
      this.$refs.uploadProgress.visible = true;
      const fileUploadResponse = await this.$apiManager.file.uploadFile(module, submodule, request.file);
      this.$refs.uploadProgress.visible = false;
      this.$store.commit('setUploadProgress', -1);
      if (fileUploadResponse.data.filePath) {
        const reader = new FileReader();
        reader.readAsDataURL(request.file);
        const params = {
          filePath: fileUploadResponse.data.filePath,
          uid: request.file.uid,
          status: 'done',
          name: request.file.name,
        };
        reader.onloadend = (e) => {
          const url = e.target.result;
          params.url = url;
          imageFile.push(params);
        };
      }
    },
    solidCustomRequest(request) {
      this.customRequest(
        request,
        this.$constRepository.file.Module.loadedDeviceType,
        this.$constRepository.file.Submodule.solidImages,
        request.data.solidImages,
      );
    },
    lineCustomRequest(request) {
      this.customRequest(
        request,
        this.$constRepository.file.Module.loadedDeviceType,
        this.$constRepository.file.Submodule.lineImages,
        request.data.lineImages,
      );
    },
  },
};
</script>

<style scoped lang="less"></style>
