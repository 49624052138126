<template>
  <a-modal
    :visible="visible"
    title="场景类型详情"
    width="40%"
    centered
    :footer="null"
    :destroyOnClose="true"
    @cancel="hideDialog"
  >
    <a-descriptions size="small" bordered :column="1">
      <a-descriptions-item label="ID">
        {{ scenceType.id }}
      </a-descriptions-item>
      <a-descriptions-item label="场景类型名称">
        {{ scenceType.name }}
      </a-descriptions-item>
      <a-descriptions-item label="发布区域">
        <span v-if="scenceType.regions">
          {{ scenceType.regions.map((region) => region.name).join('、') }}
        </span>
        <span v-else>-</span>
      </a-descriptions-item>
      <a-descriptions-item label="平台">
        <span v-if="scenceType.supportPlatforms.length">
          {{
            scenceType.supportPlatforms
              .map((platform) => $constRepository.scenceType.supportPlatformNames[platform])
              .join('、')
          }}
        </span>
        <span v-else>-</span>
      </a-descriptions-item>
      <a-descriptions-item label="状态">
        {{ switchStatus(scenceType.status) ? '启用' : '禁用' }}
      </a-descriptions-item>
    </a-descriptions>
    <div v-if="scenceType.images.length" style="margin: 20px 0;">场景类型图片数据</div>
      <a-card :tab-list="tabList" :active-tab-key="tabKey" @tabChange="(key) => (tabKey = key)">
        <div>
          <div style="margin-bottom: 10px">场景类型实体图:</div>
          <div class="clearfix">
            <a-upload
              v-if="scenceType.images[tabKey - 1]"
              list-type="picture-card"
              :file-list="scenceType.images[tabKey - 1].solidImages"
              :disabled="true"
              @preview="handlePreview"
            >
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%;background-color: #e6e6e6;" :src="previewImage" />
            </a-modal>
          </div>
        </div>
        <div>
          <div style="margin-bottom: 10px">场景类型线框图:</div>
          <div class="clearfix">
            <a-upload
              v-if="scenceType.images[tabKey - 1]"
              list-type="picture-card"
              :file-list="scenceType.images[tabKey - 1].lineImages"
              :disabled="true"
              @preview="handlePreview"
            >
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%;background-color: #e6e6e6;" :src="previewImage" />
            </a-modal>
          </div>
        </div>
      </a-card>
  </a-modal>
</template>

<script>
import lodash from 'lodash';

export default {
  name: 'ScenceTypeInfoModal',
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      visible: false,
      scenceType: {
        name: undefined,
        value: undefined,
        regionIdentifiers: [],
        supportPlatforms: [],
        regions: [],
        status: this.$constRepository.scenceType.status.enable,
        images: [],
      },
      tabList: [
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.AIHOME}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[this.$constRepository.loadedDevice.ImageTypes.AIHOME],
        },
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[
            this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE
          ],
        },
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.NEXSMART}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[this.$constRepository.loadedDevice.ImageTypes.NEXSMART],
        },
      ],
      tabKey: `${this.$constRepository.loadedDevice.ImageTypes.AIHOME}`,
    };
  },
  computed: {
    switchStatus: {
      get() {
        return (status) => status === this.$constRepository.scenceType.status.enable;
      },
    },
  },
  methods: {
    hideDialog() {
      this.visible = false;
      this.scenceType = {
        name: undefined,
        value: undefined,
        status: this.$constRepository.scenceType.status.enable,
        regionIdentifiers: [],
        supportPlatforms: [],
        regions: [],
        images: [],
      };
    },
    async showInfoDialog(scenceType) {
      await this.fetchScenceTypeById(scenceType.id);
      this.visible = true;
    },
    async fetchScenceTypeById(id) {
      const res = await this.$apiManager.scenceType.getScenceType(id);
      this.scenceType = res.data;
      if (!this.scenceType.regionIdentifiers) {
        this.$set(this.scenceType, 'regionIdentifiers', []);
        this.$set(this.scenceType, 'regions', []);
      }
      if (!this.scenceType.images) {
        this.$set(this.scenceType, 'images', []);
      }
      this.initImages();
    },
    handleCancel() {
      this.previewVisible = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handlePreview(file) {
      const imageFile = file;
      if (!imageFile.url && !imageFile.preview) {
        imageFile.preview = await this.getBase64(imageFile.originFileObj);
      }
      this.previewImage = imageFile.url || imageFile.preview;
      this.previewVisible = true;
    },
    initImages() {
      // 填充images
      if (!this.scenceType.images[0]) {
        this.scenceType.images[0] = {
          type: this.$constRepository.loadedDevice.ImageTypes.AIHOME,
          solidImages: [],
          lineImages: [],
          solidImageDownloadUrls: [],
          lineImageDownloadUrls: [],
        };
      }
      if (!this.scenceType.images[1]) {
        this.scenceType.images[1] = {
          type: this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE,
          solidImages: [],
          lineImages: [],
          solidImageDownloadUrls: [],
          lineImageDownloadUrls: [],
        };
      }
      if (!this.scenceType.images[2]) {
        this.scenceType.images[2] = {
          type: this.$constRepository.loadedDevice.ImageTypes.NEXSMART,
          solidImages: [],
          lineImages: [],
          solidImageDownloadUrls: [],
          lineImageDownloadUrls: [],
        };
      }

      this.scenceType.images.forEach((image, imageIndex) => {
        const originSolidImages = lodash.cloneDeep(image.solidImages);
        const originLineImages = lodash.cloneDeep(image.lineImages);
        this.scenceType.images[imageIndex].solidImages.splice(0, this.scenceType.images[imageIndex].solidImages.length);
        this.scenceType.images[imageIndex].lineImages.splice(0, this.scenceType.images[imageIndex].lineImages.length);
        image.solidImageDownloadUrls.forEach((url, index) => {
          const solidImage = {
            url,
            filePath: originSolidImages[index],
            name: originSolidImages[index],
            uid: -index,
          };
          this.scenceType.images[imageIndex].solidImages.push(solidImage);
        });
        image.lineImageDownloadUrls.forEach((url, index) => {
          const lineImage = {
            url,
            filePath: originLineImages[index],
            name: originLineImages[index],
            uid: -index,
          };
          this.scenceType.images[imageIndex].lineImages.push(lineImage);
        });
      });
    },
  },
};
</script>

<style scoped lang="less"></style>
